@media (min-width:320px)  {
       .App {
              text-align: center;
            }
            
            .App-logo {
              height: 40vmin;
              pointer-events: none;
            }
            
            @media (prefers-reduced-motion: no-preference) {
              .App-logo {
                animation: App-logo-spin infinite 20s linear;
              }
            }
            
            .App-header {
              background-color: #282c34;
              min-height: 100vh;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-size: calc(10px + 2vmin);
              color: white;
            }
            
            .App-link {
              color: #61dafb;
            }
            
            @keyframes App-logo-spin {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
            .header-image-logo{
              width:180px !important;
              height:90px;
              padding-top:10px
            }
            .header-image-logo-main{
              height: 90px;
            }
            /* header */
            .header-container{
              background-color: #f4c015;
              height: 130px;
              /* position: sticky; top:0px; */
              position: fixed;
              top: 0;
              width: 100%;
            padding-left: 20px;
              z-index: 1000; /* Ensure the header stays on top */
            }
            
            .header-title{
              color: black;
              text-transform: uppercase;
              border: 1px solid transparent;
              font-weight: 700;
              font-size: 12px;
              cursor: pointer;
            }
            
            
            
            .background-container {
              position: relative;
              flex: 1;
              width: 100%;
              height: 370px;
              /* z-index: 1000;  */
              overflow: hidden;
            }
            
            .background {
              position: absolute;
              top: 90px;
              left: 0;
              width: 100%;
              height: 370px;
              background-size: cover;
              background-position: center;
              opacity: 0;
              transition: opacity 2s ease-in-out; /* Smooth transition */
              z-index: -1;
            }
            .background1 {
              position: absolute;
              top: 90px;
              left: 0;
              width: 100%;
              height: 370px;
              background-size: cover;
              background-position: center;
              opacity: 1;
              z-index: -1;
            }
            
            .background.active {
              opacity: 1;
              animation: fadeAnimation 2s linear forwards; /* Animation for changing image */
            }
            
            @keyframes fadeAnimation {
              0% {
                opacity: 0.9;
              }
              100% {
                opacity: 1;
              }
            }
            
            .content{
              /* position:relative; */
            
            }
            
            .container-form{
              margin-top: 35%;
              background-color: #f4c015;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                /* overflow: hidden; */
                /* z-index: 1000; */
                /* margin-top: 9%; */
                margin-left: 0% !important;
                border-radius: 10px;
                width: 100%;
            
            }
            
            .container-form-contact{
              background-color: #f4c015;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                /* overflow: hidden; */
                /* z-index: 1000; */
                margin-top: 2%;
                margin-left: 2%;
                margin-right: 2%;
                border-radius: 10px;
                width: 100%;
            
            }
            
            /* #header {
              top: -100px;
              transition: top 0.5s ease-in-out;
            }
            
            #header.sticky {
              position: sticky;
              top: 0;
            } */
            
            .header-title-car{
              font-size: 30px;
              font-weight: 500;
              font-family: "Fira Sans Condensed", sans-serif;
            
            }
            
            
            .background-container-inside {
              position: relative;
              width: 100%;
              height: 71px;
              /* z-index: 1000;  */
              overflow: hidden;
              justify-content: center;
              display: flex;
            }
            
            .background-inside {
              position: absolute;
             
              width: 90px;
              height: 60;
              background-size: cover;
              background-position: center;
            }
            
            .hover-container-car:hover{
              background-color: #ffffff !important;
              cursor: pointer;
              border-radius: 6px;
            }
            
            .inside-image-container{
             
              display: flex;
              flex-wrap: wrap;
              width: 83%;
              align-items: center;
              justify-content: center;
            
            }
            
            .inside-car-title{
              font-size: 12px;
              font-weight: 500;
              font-family: "Fira Sans Condensed", sans-serif;
              /* margin-left: -2rem; */
            }
            
            .get-tax-button{
            
            
            width:100%;
            height:52px;
            background-color:#f4c015;
            border: 2px black solid;
            font-size:18px;
            text-transform:uppercase;
            font-weight: 700;
            font-family: "Fira Sans Condensed", sans-serif;
            
            }
            
            .get-tax-button:hover{
              color: #f4c015!important;
              background: black!important;
              border: 2px black solid;
              border-color:  black !important;
              outline: none !important;
            }
            
            .service-container{
              width:100%;
            /* height:132px; */
            background-color:#f4c015;
            }
            
            .service-name-style{
              background: transparent;
              border: 2px solid #1f1f1f;
            cursor: pointer;
              border-radius: 30px;
              padding: 10px 40px;
              margin-right: 15px;
              margin-bottom: 15px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              cursor: pointer;
              transition: transform 0.3s ease;
            }
            .service-name-style:hover{
              transform: translateY(-5px);
            }
            
            .service-name-style:hover{
              color: #ffffff!important;
              background: black!important;
              border: 2px black solid;
              border-color:  black !important;
              outline: none !important;
            }
            
            .get-tax-button-name{
            
            
              
              /* background-color:#f4c015; */
              font-size:18px;
              text-transform:uppercase;
              font-weight: 700;
              font-family: "Fira Sans Condensed", sans-serif;
              
              }
            
              .joinus-header{
                background: #2c2c2c;
                padding: 35px 0 50px 0;
                height: 161px;
                text-align: center;
                overflow: hidden;
                margin-top: 0px;
                margin-bottom: 75px;
                color: #fff;
                /* position: relative; */
              }
              .header-joinus-title{
                text-align: center;
                color: #fff;
                text-transform: uppercase;
            font-size: 48px;
            font-weight: 700;
            font-family: "Fira Sans Condensed", sans-serif;
            
              }
            
              .joinus-des{
                text-align: center;
                font-weight: bold;    font-size: 16px;
              }
            
            
              .joinus-form-des{
                margin-bottom: 6px;
                font-weight: bold;    font-size: 16px;
              }
            
              .contact-us-contact{
                background-color:#f4c015;
                height: 200px;
                padding: 35px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                width: 90%;
                margin-left: 20px;
                margin-right: 10px;
                margin-bottom: 30px;
            
              }
              /* .contact-us-contact-new{
                background-color:#fff;
                box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                height: 500px;
                
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                width: 20%;
                margin-right: 30px;
            
              } */
            
              .footer-container{
                background: #161616;
                padding: 30px 0 60px 0;
                /* position:absolute; */
            /* left:0;
            bottom:0;
            right:0; */
              }
            
              .footer-title{
                color: #f4c015;
                font-size: 24px;
                font-weight: 600;
                text-transform:uppercase;
                font-weight: 700;
                font-family: "Fira Sans Condensed", sans-serif;
            
              }
            
              .footer-title-des{
                color: #f4c015;
                font-size: 14px;
                font-weight: 500;
                /* text-transform:uppercase; */
                font-family: "Fira Sans Condensed", sans-serif;
            
              }
            
              .our-service-title{
                margin-top: 20px;
                font-weight: 700;
                font-size: 18px;
                font-family: "Fira Sans Condensed", sans-serif;
              }
              .our-service-title-des{
                margin-top: 20px;
                font-weight: 400;
                font-size: 14px;
                color: #1f1f1f;
                font-family: "Fira Sans Condensed", sans-serif;
                /* width: 30%; */
              }
              .our-service-ctn2{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-right: 20px;
                margin-top: 20px;
              }
            
              .traffic-container{
                background-color: #f5f5f5;
                padding-top: 25px;
               
              }
            
              .taffic-card-ctn{
                margin-top: 20px;
                background-color:#f4c015;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                /* margin-right: 20px; */
                /* margin-bottom: 20px; */
                width: 100%;
                height: 445px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                transition: transform 0.3s ease;
                cursor: pointer;
              }
              .taffic-card-ctn:hover {
                transform: translateY(-5px);
              }
              .taffic-card-ctn-title{
                /* margin-top: 20px; */
                font-weight: 700;
                font-size: 25px;
                color: #1f1f1f;
                font-family: "Fira Sans Condensed", sans-serif;
                /* width: 30%; */
              }
            
              .taffic-card-ctn-title-des{
                margin-top: 20px;
                font-weight: 700;
                font-size: 16px;
                color: #1f1f1f;
                font-family: "Fira Sans Condensed", sans-serif;
                /* width: 30%; */
              }
            
              .taffic-card-ctn-price{
                margin-top: 20px;
                font-weight: bold;
                font-size: 26px;
                color: #1f1f1f;
                font-family: "Fira Sans Condensed", sans-serif;
                /* width: 30%; */
              }
             .traffic-container-join-us{
              background-color: #fff;
             }
            
             .join-support-ctn{
              display: flex;
              align-items: center;
              margin-top: 20px;
             }
            
             .join-support-text{
              margin-left: 10px;
              font-weight: 700;
              font-size: 16px;
              color: #1f1f1f;
              font-family: "Fira Sans Condensed", sans-serif;
             }
            
             .join-us-home-btn:hover{
              background-color: #161616 !important;
              color: #f4c015 !important
             }
            
             .contact-us-contact-ctn{
              width: 100%;
              height: 90px;
              border-bottom: #000;
              border-width: 6px;
              /* border-bottom-width: 1px solid #000; */
              border-bottom-width: thick;
              border-bottom: 2px solid #D6D6D6;
              display: flex;
              justify-content:center;
              align-items: center;}
            
            .contact-us-contact-ctn-text{
              text-align: 'center';
              font-weight: 700;
              font-size: 16px;
              color: #1f1f1f;
              font-family: "Fira Sans Condensed", sans-serif;
            }
            .contact-us-contact-new{
              border-top: solid 2px #ffc61a !important;
              border-bottom: solid 2px #ffc61a !important;
              border-right: solid 2px #ffc61a !important;
              border-left: solid 2px #ffc61a !important;
              background-color:#fff;
              box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
              height: 500px;
              /* padding: 35px; */
              display: flex;
              /* justify-content: center; */
              flex-direction: column;
              align-items: center;
              width: 95%;
              margin-left: 20px;
              /* margin-right: 30px; */
            
            }
            .tariff-box{
              background-color: #1f1f1f;
              border-top: solid 1px #ffc61a !important;
              border-bottom: solid 1px #ffc61a !important;
              border-right: solid 1px #ffc61a !important;
              border-left: solid 1px #ffc61a !important;
            }
            
            .tariff-text{
              color: #ffc61a;
              text-align: center;
              font-size: 24px;
              font-weight: 500;
            }
            .tariff-text-1{
              color: #1f1f1f;
              text-align: center;
              font-size: 20px;
              font-weight: 500;
            }
            .tariff-text-2{
              color: #1f1f1f;
              text-align: center;
              font-size: 18px;
              font-weight: 500;
            }
            
            .tariff-box-1{
              background-color: #c8c8c8;
            }
            
            .table-container {
              width: 500px; /* Adjust as needed */
              overflow-x: auto; /* Add horizontal scroll if needed */
            }
            
            table {
              width: 100%;
              border-collapse: collapse;
            }
            
            table, th, td {
              border-top: solid 1px #ffc61a !important;
              border-bottom: solid 1px #ffc61a !important;
              border-right: solid 1px #ffc61a !important;
              border-left: solid 1px #ffc61a !important;
            }
            
            th, td {
              padding: 20px;
              color: #1f1f1f;
              text-align: center;
              font-size: 16px;
              font-weight: 500;
            }
            .table-cell-mobile{
              padding: 8px !important;
              color: #1f1f1f;
              text-align: center;
              font-size: 16px;
              font-weight: 500;
              height: 15px !important;
            }
            .table-cell{
            
            }
            
            .floating-icon {
              display: flex;
              position: fixed;
              bottom: 20px; /* Adjust as needed */
              right: 20px; /* Adjust as needed */
              z-index: 1000; /* Ensure it appears above other content */
            }
            
            .pay-images{
              justify-content: center;
              display: flex;
            
            }

       }